:root {
  --primary-color: #4F6A65;
  --email-color: #bf389b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #20262D !important; */ /* dark */
  background-color: #CDDFD2 !important; /* light */
  /* background: linear-gradient(to bottom, #cadfb9, #ECE6DE); */
  /* background: linear-gradient(to bottom, #20262D, #44566b); */
  /* min-height: 300vh; */
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  width: 100%;
  height: 100%;
}

canvas.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}



/* Text styles */
.bigText {
	font-size: 88px !important;
	font-weight: bold !important;
	color: var(--primary-color);
}

@media (max-width: 425px) {
  .bigText {
    font-size: 48px !important;
  }
}

.regularText {
	font-size: 18px !important;
	font-weight: normal !important;
	color: var(--primary-color) !important;
}

@media (max-width: 425px) {
  .regularText {
    font-size: 22px !important;
  }
}

.regularText a {
  font-weight: bold;
  color: var(--email-color) !important;
}

.regularText a:hover {
  text-decoration: none;
}

.projectName, .letsWorkText {
  font-size: 40px !important;
	font-weight: bold !important;
	color: var(--primary-color) !important;
}

.letsWorkText {
  margin-bottom: 20px !important;
}

@media (max-width: 425px) {
  .letsWorkText {
    font-size: 36px !important;
    margin-bottom: 10px !important;
  }
}

.linkText {
  font-size: 18px !important;
  font-weight: bold !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer;
}

.linkText:hover {
  text-decoration: underline !important;
}

.featureText {
	font-size: 18px !important;
	font-weight: normal !important;
	color: var(--primary-color) !important;
}

@media (max-width: 425px) {
  .featureText {
    font-size: 18px !important;
  }
}

.stackText {
  font-size: 12px !important;
  font-weight: normal !important;
  color: var(--primary-color) !important;
}

.offeringsList {
  padding-left: 15px;
}

.offeringsList li {
  padding-bottom: 7px;
}

.offeringStyle {
  font-weight: bold;
}



/* Animations */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.2;
  }
}
